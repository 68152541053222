import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import globalStore from "./globalStore";
import "./assets/styles/global.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={globalStore}>
    <App />
    <ToastContainer />
  </Provider>
);
reportWebVitals();
