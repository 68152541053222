import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// import UserDashboard from "./pages/UserDashboard/UserDashboard";
import ScrollToTop from "./components/specific/ScrollToTop";

import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

import ReactLoading from "react-loading";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
// import { Home } from "@mui/icons-material";

import logo from "./assets/images/flcs_logo.png";

const HomePage = lazy(() => import("./pages/HomePage/HomePage"));
const CoursesPage = lazy(() => import("./pages/CoursesPage/CoursesPage"));
const UniversitiesPage = lazy(() =>
  import("./pages/UniversitiesPage/UniversitiesPage")
);
const ShowUniversity = lazy(() =>
  import("./pages/ShowUniversityPage/ShowUniversity")
);
const ShowCourse = lazy(() => import("./pages/ShowCoursePage/ShowCourse"));
const DashboardPage = lazy(() => import("./pages/DashboardPage/DashboardPage"));
const BlogsPage = lazy(() => import("./pages/BlogsPage/BlogsPage"));
const ShowBlog = lazy(() => import("./pages/ShowBlog/ShowBlog"));
const AboutUsPage = lazy(() => import("./pages/AboutUs/AboutUs"));

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black", // Border color on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(3,163,137)", // Border color when focused
          },
        },
        notchedOutline: {
          borderColor: "#9E9E9E", // Default border color
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "rgb(3,163,137)", // Label color when focused
          },
        },
      },
    },
  },
});

const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <div style={{ width: "5rem" }}>
        <img src={logo} alt="" style={{ width: "6rem" }} />
        <ReactLoading
          type="cylon"
          color="rgb(3,163,137)"
          height={18}
          width={80}
        />
      </div>
    </div>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* <LoadingBar /> */}
      <Router>
        <ScrollToTop />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/explore-courses" element={<CoursesPage />} />
            <Route
              path="/explore-universities"
              element={<UniversitiesPage />}
            />
            <Route path="/university/:slug" element={<ShowUniversity />} />
            <Route path="/course/:slug" element={<ShowCourse />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/blogs" element={<BlogsPage />} />
            <Route path="/blog/:slug" element={<ShowBlog />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            {/* <Route path="/dashboard" element={<UserDashboard />} /> */}
          </Routes>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
}

export default App;
